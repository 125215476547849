
	@import "../../variables.less";
	@import "../../resource/common.less";
	.mainContent2 {
		.main-info {
			width: 100%;
			height: 100%;
			position: absolute;
			right: 0;
			right: 0;
			.info {
				width: 100%;
				height: 32%;
				border-radius: 4px;
                background:@contentBg;
                box-shadow: 0 0 10px 1px @boxshadow;
                margin: 10px;
				.title1 {
					color: #f1f3e2;
					height: 18%;
					font-size: 16px;
					line-height: 26px;
					padding-left: 20px;
				}
				.title2 {
					height: 8%;
				}
				.spe1 {
					height: 80%;
					width: 100%;
					display: flex;
					.overview {
						width: 50%;
						&>p {
							font-size: 14px;
                            font-weight: 600;
							padding-left: 12px;
						}
						.line {
							width: 1px;
							background: #eeeff0;
							height: 54%;
							margin-top: 10%;
						}
						.over-info {
							width: 100%;
							height: 85%;
							display: flex;
							.over-item {
								width: 48%;
								.item {
									height: 50%;
									width: 100%;
									display: flex;
                                    align-items: center;
                                    justify-content: center;
								}
								img{
									width: 24px;
									height: 24px;
									margin-right: 1px;
								}
								.status {
                                    width: 80px;
                                    height: 60px;
									margin-left: 10px;
                                    text-align:center;
									p {
										color: @colorGary;
										font-size: 30px;
                                        font-weight: 600;
									}
                                    .red{
										color: @colorRed;
                                    }
                                    .yellow{
										color: @colorYellow;
                                    }
                                    .green{
										color: @colorGreen;
                                    }
									span {
										color: @colorGary;
									}
								}
							}
						}
					}
					.week-e {
						width: 50%;
						height: 100%;
					}
				}
			}
			.info2 {
				width: 100%;
				height: 65%;
				display: flex;
				.info2-item {
                    border-radius: 4px;
                    background:@contentBg;
                    box-shadow: 0 0 10px 1px @boxshadow;
                    margin: 10px;
					width: 48%;
					height: calc(100% - 10px);
					margin: 0 1%;
					.info2-list {
						width: 100%;
						height: 45%;
						overflow: auto;
					}
					.info2-echart {
						width: 100%;
						height: 45%;
					}
				}
			}
		}
	}
    .dot-info {
        width: 100%;
        height: 500px;
        .in1 {
            width: 100%;
            height: 44%;
            .line1 {
                padding: 10px 2px;
                display: flex;
                span {
                    flex: 2;
                    text-align: center;
                    color:#999;
                }
                p {
                    flex: 3;
                    color:#333;
                    text-align: center;
                }
            }
        }
        .echart-dot {
            margin: 60px 0 0;
            width: 100%;
            height: 40%;
        }
    }
