.mainContent {
  width: 400px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.mainContent2 .main-info {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
}
.mainContent2 .main-info .info {
  width: 100%;
  height: 32%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.mainContent2 .main-info .info .title1 {
  color: #f1f3e2;
  height: 18%;
  font-size: 16px;
  line-height: 26px;
  padding-left: 20px;
}
.mainContent2 .main-info .info .title2 {
  height: 8%;
}
.mainContent2 .main-info .info .spe1 {
  height: 80%;
  width: 100%;
  display: flex;
}
.mainContent2 .main-info .info .spe1 .overview {
  width: 50%;
}
.mainContent2 .main-info .info .spe1 .overview > p {
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
}
.mainContent2 .main-info .info .spe1 .overview .line {
  width: 1px;
  background: #eeeff0;
  height: 54%;
  margin-top: 10%;
}
.mainContent2 .main-info .info .spe1 .overview .over-info {
  width: 100%;
  height: 85%;
  display: flex;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item {
  width: 48%;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .item {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item img {
  width: 24px;
  height: 24px;
  margin-right: 1px;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status {
  width: 80px;
  height: 60px;
  margin-left: 10px;
  text-align: center;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status p {
  color: #788CA1;
  font-size: 30px;
  font-weight: 600;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status .red {
  color: #FD4848;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status .yellow {
  color: #EF9F24;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status .green {
  color: #26cc63;
}
.mainContent2 .main-info .info .spe1 .overview .over-info .over-item .status span {
  color: #788CA1;
}
.mainContent2 .main-info .info .spe1 .week-e {
  width: 50%;
  height: 100%;
}
.mainContent2 .main-info .info2 {
  width: 100%;
  height: 65%;
  display: flex;
}
.mainContent2 .main-info .info2 .info2-item {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
  width: 48%;
  height: calc(100% - 10px);
  margin: 0 1%;
}
.mainContent2 .main-info .info2 .info2-item .info2-list {
  width: 100%;
  height: 45%;
  overflow: auto;
}
.mainContent2 .main-info .info2 .info2-item .info2-echart {
  width: 100%;
  height: 45%;
}
.dot-info {
  width: 100%;
  height: 500px;
}
.dot-info .in1 {
  width: 100%;
  height: 44%;
}
.dot-info .in1 .line1 {
  padding: 10px 2px;
  display: flex;
}
.dot-info .in1 .line1 span {
  flex: 2;
  text-align: center;
  color: #999;
}
.dot-info .in1 .line1 p {
  flex: 3;
  color: #333;
  text-align: center;
}
.dot-info .echart-dot {
  margin: 60px 0 0;
  width: 100%;
  height: 40%;
}
